import React from 'react';
import Layout from '../../components/Shared/Layout';
import Fasset from '../../components/Portfolio/fasset';
import '../../assets/css/sesame.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;

class FassetPage extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Portfolio/fasset',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <Fasset />
      </Layout>
    );
  }
}

export default FassetPage;
